import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { styled } from 'styled-components';

import fetchRequest from './Libs/FetchRequest';

const ContentDetails = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    align-items: center;

    > div:first-child {
        text-align: center;
        grid-row: 1 / 7;
    }

    > div > i {
        font-size: 3.5em;
        color: #2d3a6699;
    }
`;

const DataSection = styled.div`
    span {
        font-weight: bold;
    }
`;

const Details = ({open, messageId, loader}) => {
    const [ details, setDetails ] = useState({});

    useEffect(() => {
        if (open) {
            GetDetails();
        }
    }, [open]); //eslint-disable-line

    const GetDetails = async () => {
        // loader.current.handleShow();

        let response = await fetchRequest({
            url: "messages/details/" + messageId
        });

        if (response !== undefined) {
            setDetails({
                length: response.length,
                solarCurrent: response.solarCurrent,
                batteryCurrent: response.batteryCurrent,
                solarVoltage: response.solarVoltage,
                latitude: response.latitude,
                batteryVoltage: response.batteryVoltage,
                numberOfMessages: response.numberOfMessages,
                altitude: response.altitude,
                speed: response.speed,
                course: response.course,
                lastRSSI: response.lastRSSI,
                tileCurrent: response.tileCurrent
            });
        }
        else {
            setDetails({});
        }

        // loader.current.handleClose();
    }

    return (
        <AnimatePresence initial={false}>
            {open && 
                <tr>
                    <td colSpan={6} style={{overflow: 'hidden'}}>
                        <motion.div
                            key="content"
                            initial="collapsed"
                            animate="open"
                            exit="collapsed"
                            variants={{
                                open: { opacity: 1, height: "auto" },
                                collapsed: { opacity: 0, height: 0 }
                            }}
                            transition={{ duration: 0.3, ease: "easeIn" }}
                        >
                            <motion.div
                                transition={{ duration: 0.3 }}
                            >
                                <ContentDetails>
                                    <div>
                                        <i className="fa-solid fa-satellite"></i>
                                    </div>
                                    <DataSection>
                                        <span>Latitude:</span> {details.latitude}°
                                    </DataSection>
                                    <DataSection>
                                        <span>Longitude:</span> {details.length}°
                                    </DataSection>
                                    <DataSection>
                                        <span>Altitude:</span> {details.altitude === null ? "" : details.altitude + "m"}
                                    </DataSection>
                                    <DataSection>
                                        <span>Speed:</span> {details.speed === null ? "" : details.speed + "km/h"}
                                    </DataSection>
                                    <DataSection>
                                        <span>Solar current:</span> {details.solarCurrent === null ? "" : details.solarCurrent + "A"}
                                    </DataSection>
                                    <DataSection>
                                        <span>Solar voltage:</span> {details.solarVoltage === null ? "" : details.solarVoltage + "V"}
                                    </DataSection>
                                    <DataSection>
                                        <span>Battery current:</span> {details.batteryCurrent === null ? "" : details.batteryCurrent + "A"}
                                    </DataSection>
                                    <DataSection>
                                        <span>Battery voltage:</span> {details.batteryVoltage === null ? "" : details.batteryVoltage + "V"}
                                    </DataSection>
                                    <DataSection>
                                        <span>Course:</span> {details.course === null ? "" : details.course + "°"}
                                    </DataSection>
                                    <DataSection>
                                        <span>Last RSSI value:</span> {details.lastRSSI === null ? "" : details.lastRSSI + "dBm"}
                                    </DataSection>
                                    <DataSection>
                                        <span>Tile current:</span> {details.tileCurrent === null ? "" : details.tileCurrent + "A"}
                                    </DataSection>
                                </ContentDetails>
                            </motion.div>
                        </motion.div>
                    </td>
                </tr>
            }
        </AnimatePresence>
    );
}
 
export default Details;