import React from 'react';
import { styled } from 'styled-components';

const FormGroupContainer = styled.div`
    margin-bottom: 0.5em;
    label, input {
        display: block;
    }

    label {
        margin-bottom: 0.25em;
    }

    input, button {
        border-radius: 0.25em;
        padding: 0.25em 1em;
    }
    
    input {
        border: 1px solid rgb(190, 190, 190);
        width: 100%;
        transition: 
            border-color 150ms ease,
            box-sahadow 150ms ease;

        &:focus-visible {
            outline: none;
            border-color: #2d3a66;
            box-shadow: 0 0 5px #2d3a6644;
        }
    }
`;

const FormGroup = ({children}) => (
    <FormGroupContainer>
        {children}
    </FormGroupContainer>
)
 
export default FormGroup;