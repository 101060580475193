import moment from "moment";

export const ToLocalDate = (utcDate) => {
    let date = moment(utcDate, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
    
    let stillUtc = moment.utc(date).toDate();
    
    let local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');

    return local;
}