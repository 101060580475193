import React from 'react';
import { styled } from 'styled-components';

const ButtonContainer = styled.button`
    background-color: #2d3a6655;
    border: 1px solid #2d3a66;
    transition: 
        background-color 150ms ease,
        color 150ms ease;

    &:hover {
        background-color: #2d3a66;
        color: white;
    }
`;

const Button = ({props, children}) => (
    <ButtonContainer {...props}>
        {children}
    </ButtonContainer>
)
 
export default Button;