import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';

const Logo = require('./Assets/Images/Logo.jpg');

const Container = styled.div`
    min-height: 100dvh;
    background-color: rgb(225, 225, 225);
`;

const Header = styled.div`
    align-items: center;
    background-color: #000;
    color: white;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1em;

    > div:first-child {
        max-width: 100px;
    }

    > div {
        display: flex;
        gap: 0.5em;
        flex: 1 1;
    }

    button {
        border: none;
        background-color: transparent;
        border-radius: 0.25em;
        color: white;
        transition: 
            background-color 150ms ease,
            color 150ms ease;

        &:hover {
            background-color: white;
            color: #272727;
        }
    }
`;

const Main = styled.div`
    padding: 1rem;
`;

const Content = styled.div`
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    margin-inline: auto;
    padding: 1rem;
    width: clamp(75%, 400px, 100%);

    h2 {
        text-align: center;
    }

    > * + * {
        margin-top: 1rem;
    }
`;

const Layout = () => {
    const navigate = useNavigate();

    const GoTo = (rute) => {
        navigate(rute);
    }

    const CloseSession = () => {
        sessionStorage.clear();

        navigate("/");
    }

    return (
        <Container>
            <Header>
                <div>
                    <img src={Logo} alt='logo' />
                </div>
                <h2>SpaceApp MESSAGE DISTRIBUTOR SYSTEM</h2>
                <div>
                    <button onClick={() => GoTo("table")}>
                        <i className="fa-solid fa-table-list"></i>
                        &nbsp;
                        List
                    </button>
                    <button onClick={() => GoTo("chart")}>
                        <i className="fa-solid fa-chart-line"></i>
                        &nbsp;
                        Chart
                    </button>
                </div>
                <button onClick={CloseSession}>
                    Close Session
                </button>
            </Header>
            <Main>
                <Content>
                    <Outlet />
                </Content>
            </Main>
        </Container>
    );
}
 
export default Layout;