import React, { Fragment } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import ChartReport from './ChartReport';
import Layout from './Layout';
import Login from './Login';
import Table from './Table';

const ProtectedRoute = () => {
    if (sessionStorage.getItem("login") !== "ADMIN") {
        return <Navigate to="/" replace />;
    }

    return <Outlet />;
}

const App = (props) => {
    return (
        <Fragment>
            <Routes>
                <Route path='/' exact element={<Login />}/>

                <Route element={<ProtectedRoute />} >
                    <Route element={<Layout {...props} />} >
                        <Route path="/table" exact element={<Table />} />
                        <Route path="/chart" exact element={<ChartReport />} />
                    </Route>
                </Route>
            </Routes>
        </Fragment>
    );
}
 
export default App;