import React, { Fragment, useEffect, useState } from 'react';
import { styled } from 'styled-components';
import moment from 'moment';

import Details from './Details';

import fetchRequest from './Libs/FetchRequest';
import { ToLocalDate } from './Libs/Functions';

const Form = styled.div`
    align-items: flex-end;
    display: flex;
    gap: 1em;

    label, input {
        display: block;
    }

    label {
        margin-bottom: 0.25em;
    }

    input, button {
        border-radius: 0.25em;
        padding: 0.25em 1em;
    }
    
    input {
        border: 1px solid rgb(190, 190, 190);
        width: 100%;
        transition: 
            border-color 150ms ease,
            box-sahadow 150ms ease;

        &:focus-visible {
            outline: none;
            border-color: #2d3a66;
            box-shadow: 0 0 5px #2d3a6644;
        }
    }

    button {
        background-color: #2d3a6655;
        border: 1px solid #2d3a66;
        transition: 
            background-color 150ms ease,
            color 150ms ease;

        &:hover {
            background-color: #2d3a66;
            color: white;
        }
    }
`;

const TableContainer = styled.table`
    width: 100%;
    
    thead {
        background-color: #2d3a6655;
    }

    th, td {
        padding: 0.5em 0.75em;
    }
`;

const DataRow = styled.tr`
    background-color: ${props => props.pos % 2 === 0 ? "white" : "#2d3a6611" };
    cursor: pointer;
`;

const Table = ({ loader }) => {
    const [ selectedRow, setSelectedRow ] = useState(0);
    const [ filters, setFilters ] = useState({
        fromDate: moment(Date.now()).format("YYYY-MM-DD"),
        toDate: '',
        timeOffset: moment(Date.now()).utcOffset() / 60
    })
    const [ data, setData ] = useState([]);

    const {
        fromDate,
        toDate,
        timeOffset
    } = filters;

    useEffect(() => {
        console.log(timeOffset);
        
        GetData();
    }, []); //eslint-disable-line

    const HandleChange = (e) => {
        console.log(e);
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        })
    }

    const GetData = async () => {
        // loader.current.handleShow();

        let url = "messages?zone=" + timeOffset;

        if (fromDate !== '') {
            url += '&from=' + fromDate;
        }

        if (toDate !== '') {
            url += '&to=' + toDate;
        }

        let response = await fetchRequest({
            url: url
        });

        if (response !== undefined) {
            let dataList = response.map(r => {
                return {
                    messageId: r.messageId,
                    device: r.device,
                    packetId: r.packetId,
                    hiveRxTime: ToLocalDate(r.hiveRxTime),
                    messageNumber: r.messageNumber,
                    createdTimestamp: ToLocalDate(r.createdTimestamp),
                    deltaTime: r.deltaTime
                };
            });

            setData(dataList);
        }
        else {
            setData([]);
        }

        setSelectedRow(0);

        // loader.current.handleClose();
    }

    const OnOpen = (id) => {
        if (selectedRow === id) {
            setSelectedRow(0);
        }
        else {
            setSelectedRow(id);
        }
    }

    return (
        <Fragment>
            <h1>List SOH Records</h1>
            <Form>
                <div>
                    <label>From:</label>
                    <input type='date' name='fromDate' value={fromDate} onChange={HandleChange} />
                </div>
                <div>
                    <label>To:</label>
                    <input type='date' name='toDate' value={toDate} onChange={HandleChange} />
                </div>
                <button onClick={GetData}>
                    <i className="fa-solid fa-magnifying-glass"></i>
                    &nbsp;
                    Search
                </button>
            </Form>
            <TableContainer>
                <thead>
                    <tr>
                        <th>Dev Name</th>
                        <th>Hive Pkt ID</th>
                        <th>Rcv Timestamp</th>
                        <th>Msg #</th>
                        <th>Cr Timestamp</th>
                        <th>Delta Time</th>
                    </tr>
                </thead>
                <tbody>
                {data.map((d, i) => (
                    <Fragment key={i} >
                        <DataRow pos={i} onClick={() => OnOpen(d.messageId)}>
                            <td>{d.device}</td>
                            <td>{d.packetId}</td>
                            <td>{d.hiveRxTime}</td>
                            <td>{d.messageNumber}</td>
                            <td>{d.createdTimestamp}</td>
                            <td>{d.deltaTime}</td>
                        </DataRow>
                        <Details 
                            open={selectedRow === d.messageId} 
                            messageId={d.messageId}
                            loader={loader}
                        />
                    </Fragment>
                ))}
                </tbody>
            </TableContainer>
        </Fragment>
    );
}
 
export default Table;