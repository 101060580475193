/**
 * Función para consulta al API
 * @param {object} data Configuración de la petición
 * @returns Resultado de la consulta
 */
const fetchRequest = ({
    url,
    method = 'GET',
    obj = null,
    requireToken = true
})=>{
    let token = "JSESSIONID=eyJraWQiOiJidW1ibGViZWUwIiwidHlwIjoiSldUIiwiYWxnIjoiUlMyNTYifQ.eyJvcmciOjY1MDE4LCJyb2xlcyI6WzJdLCJpc3MiOiJTd2FybSBUZWNobm9sb2dpZXMsIEluYy4iLCJ1c2VyIjoyNDkwLCJqdGkiOi02MTQzMTczMzMsInVzZXJuYW1lIjoiaW1vZGFsLXN3YXJtIn0.AnZSbq4fJ3iQbUJ4BYUqH851Cd5cEZ-ujN4e2RRpvGsTEzj_lCqZwojWCcD--nhbUphxiyXnMMODowQfri9_TjfdiadwX9YDlGwNXBccWr6Hfrb7O2tZdX99x8nNUHRQEODQShSkYkn9dRGcnj1q4Ttfv8PRh3Sh_70hf1Y93z8";

    let api_url = "https://api.spaceapp.space/api/"
    // let api_url = "https://localhost:443/api/"

    return fetch( api_url + url,{
        method: method,
        body: obj !== null ? JSON.stringify(obj) : null,
        headers: new Headers(
        	requireToken ?
                {
                    'cookie' : token,
                    'Content-Type'  : 'application/json',
                    'Accept'        : 'application/json'
                }
            : 
                {
                    'Content-Type'  : 'application/json',
                    'Accept'        : 'application/json'
                }
        )
    }).then(res => {
        switch(res.status){
            case 200:
                return res.json();
            default:
                return "";
        }
    }).then(response => {
        if(response !== undefined){
            return response;            
        }
    }).catch(error => {
        console.log(error);
    })
};

export default fetchRequest;
