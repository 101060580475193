import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';

import Button from './Components/Button';
import FormGroup from './Components/FormGroup';

const LoginContainer = styled.div`
    background-color: #2d3a66;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100dvh;

    > div {
        background-color: white;
        border-radius: 1em;
        padding: 2em;
    }

    div > button {
        display: block;
        width: 100%;
    }
`;

const Login = () => {
    const navigate = useNavigate();

    const [ formData, setFormData ] = useState({
        username: '',
        password: ''
    });

    const {
        username,
        password
    } = formData;

    const HandleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const OnLogin = (e) => {
        e.preventDefault();

        if (username === "ADMIN" && password === "SitiLab2023") {
            sessionStorage.setItem("login", username);

            navigate("/table");

            return;
        }
        
        alert("Username or password not valid");
    }

    return (
        <LoginContainer>
            <div>
                <h1>
                    <i className="fa-solid fa-satellite-dish"></i>
                    &nbsp;
                    Login
                </h1>
                <form autoComplete='off' onSubmit={OnLogin}>
                    <FormGroup>
                        <label>Username</label>
                        <input 
                            type='text' 
                            name='username' 
                            value={username}
                            onChange={HandleChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>Password</label>
                        <input 
                            type='password' 
                            name='password' 
                            value={password}
                            onChange={HandleChange}
                        />
                    </FormGroup>
                    <div>
                        <Button>Enter</Button>
                    </div>
                </form>
            </div>
        </LoginContainer>
    );
}
 
export default Login;